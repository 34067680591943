*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

  }
  body {
    width: 100%;

  display: grid;
  align-items: center;
  }
  
  
  .App{
    width: 100%;
    height:100%;
   /* For browsers that do not support gradients */
    display: grid;
  }
  .Home{
    height: 100%;
    width: 100%;
    display: grid;

  }
  .dropbtn {

  }
  
  .dropdown {


  }
  
  .dropdown-content {
    background-color: #0f172a;
    display: none;
    position: absolute;
    top:"0";

    z-index: 4;
  }
  
  .dropdown:hover .dropdown-content {display:grid;}
  
  .hvga1{
   /*background-image: linear-gradient(200deg, #212832 45%, #000000 1%);*/

  height: 100vh;
  width: 100%;
  display: grid;
  background-color: #000000;
  grid-template-areas: 
  'logo itensm'
  'sub1 sub1'
  'sub1 sub1'
  'sub1 sub1 ';

  align-items: center;
}
@keyframes movingGradient {
  from {background-position: 0 0;}
  to {background-position:100% 100%;}
}
.logo1{
grid-area: logo;
position: absolute;
top: 20%;
height: 10vh;
width: 10vh;
background-size: 100%;
background-position: center;
background-repeat: no-repeat;

}
.sub1{
  position: absolute;
  top: 50%;
  grid-area: sub1;
height:20%;
width: auto;
text-align: left;
margin: auto auto;
font-weight: bold;
font-size: 2.2vh;
color: rgb(0, 0, 0);

}
.sub1 span{
  grid-area: sub;
height: min-content;
width: auto;
text-align: center;
font-weight: bold;
margin: auto auto;
font-size: 10vh;
color: rgb(0, 0, 0);

}
.sub1 ul{
  display: flex;
}
.sub1 ul li{
  height: 6vh;
  width: 18vh;
  background-color: #212832;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
border: none;
font-size: 2.0vh;
cursor: pointer;
display: grid;
margin-right: 2%;
}
.sub1 ul li a{
height: min-content;
width: auto;
margin: auto auto;
  text-decoration: none;
  color: rgb(0, 0, 0);
}
.redes{
  position: absolute;
  top: 85%;
  left: 1%;
  width: 30vh;
  height: 10vh;
  margin-bottom: 10vh;
  display: flex;
grid-area: redes;
}

.redes a{
height: min-content;
width:min-content;
margin: auto;
border: none;
text-decoration: none;

}
.instagram{

  width:7vh;
  height:7vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size:40%;
  background-position: center;
  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
}
.telegram{

  width:7vh;
  height:7vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size:40%;
  background-position: center;

  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
}
.whatsapp{

  width:7vh;
  height:7vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size:55%;
  background-position: center;

  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
}
.discord{
  display: none;

  width:7vh;
  height:7vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size:40%;
  background-position: center;

  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
 

}
.twiter{

  width:7vh;
  height:7vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size:40%;
  background-position: center;

  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
}
.facebook{

  width:7vh;
  height:7vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size:27%;
  background-position: center;

  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
}

.menui1{
  position: absolute;
  top: 0%;
  right: 24%;
  width: 10vh;
height: 10vh;

display: grid;
grid-template-columns: min-content min-content min-content;


}
.menui1 li{
  width: 14vh;
height: min-content;
padding: 2vh;
display: flex;
margin: auto auto;
background-color: #000000;
border-radius: 10px;
}
.menui1 li a{
  width: auto;
height: min-content;
font-size: 2vh;
margin: auto;
color: rgb(255, 255, 255);
text-decoration: none;
cursor: pointer;
font-weight: bold;
}
.menui1 li span{
  width: auto;
height: min-content;
font-size: 2vh;
margin: auto;
color: rgb(255, 255, 255);
font-weight: bold;
}
  .footercr{
    height: 10vh;
    font-size: 2.5vh;
    margin-bottom: 10vh;
    color: rgb(0, 0, 0);
    text-align: center;
    }
    .footercr span{
    
        font-size: 2vh;
        color: rgb(255, 255, 255);
        }

  .hvga2{ /* For browsers that do not support gradients */
    height: 100vh;
    width: 100%;
    display: grid;
  grid-template-columns: 50% 50%;
    align-items: center;
background-repeat: no-repeat;
background-position: right;
background-size: 50%;

  }
  .lala{
    background-position: left;
  }
  .hvga2a{
height: 50%;
width: 95%;
margin: auto;
display: grid;
white-space: normal;
padding: 2%;

border-radius: 5px;
  }
  .hvga2a h1{
    color: #575757;
    font-size: 2.5vh;
    text-align: left;
  }
  .hvga2a h1 span{
    color: #000000;
    font-size: 8vh;
    font-weight: bold
  }
  

  .hvga3 {
 /* For browsers that do not support gradients */
    height: 70vh;
    width: 100%;
    display: grid;
    grid-template-areas: 
    'title title title'
 ;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 14%;

  }
  .hvga3 h2{
    height: min-content;
    width: 100%;
        text-align: left;
    grid-area: title;
    font-size: 3vh;
    color: rgb(0, 0, 0);

  }
  .hvga3 div{
    height: 60%;
    width: 70%;
    background-color: #000000;
    border-radius: 360px;
    margin: auto auto;
    padding: 2%;
    display: grid;
    grid-template-rows: 70%;
  }
  
  .hvga3 div h1{
    color: #fcfcfc;
    font-size: 2.5vh;
    text-align: center;
    height: min-content;
    width: 80%;
    margin: auto;
  }
  .hvga3 div h1 span{
    color: #fcfcfc;
    font-size: 4vh;


  }
  .hvga3 div h1 a{
    color: #212832;
    font-size: 3vh;
    text-align: center;
    height: min-content;
    width: 80%;
    margin: auto;
  }

  .hvga4{
    background-color: #212832; /* For browsers that do not support gradients */
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-areas: 
    'title'
 ;
    align-items: center;
    background-image: linear-gradient( #000000 45%, #000000 1%);
  }
  .hvga4 h2{
    height: min-content;
    width: auto;
    text-align: center;
    grid-area: title;
    font-size: 5vh;
    color: rgb(0, 0, 0);
margin: auto auto;

  }
  .hvga4 div{
    height: min-content;
    width: 80%;
    background-color: #000;
    border-radius: 10px;
    margin: auto auto;
    border: 2px #212832 solid;
    padding: 2%;
    display: grid;
  }
  .hvga4 div h1{
    color: #000000;
    font-size: 3vh;
    text-align: left;
    height: min-content;
    width: 100%;
    margin: auto;
    
  }
  .hvga4 div h1 span{
    color: #000000;
    font-size: 3vh;
  }

  .hvga4 div div{
display: flex;
width: 95%;
  }
.hvga4 div a{
    height: 7vh;
    width: 20vh;
    background-color: #212832;
    border-radius: 10px;
    text-align: center;
  border: none;
  font-size: 2.2vh;
  cursor: pointer;
  display: grid;
  margin-right: 2%;
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding-top: 2vh;
  }
  .hvga4 div a:hover{
   
    background-color: #232933;


  }

  .hvga5{
    background-color: #ffffff00; /* For browsers that do not support gradients */
    height: min-content;
    width: 100%;
    display: grid;
  }
  .hvga5 h1{
    text-align: center;
    color: #ffffff;
 }
  .timeline {
    position: relative;
    padding: 1rem;
    margin: 0 auto;
    max-width: 1300px;
    width: 80%;
 }
  .timeline:before {
    content: '';
    position: absolute;
    height: 100%;
    border: 1px solid #212832;
 
    right: 40px;
    top: 0;
 }
  .timeline:after {
    content: "";
    display: table;
    clear: both;
 }
  @media screen and (min-width: 700px) {
    .timeline {
      padding: 2rem;
   }
    .timeline:before {
      left: calc(50% - 1px);
      right: auto;
   }
 }
  .timeline__item {
    padding: 1rem;
    border: 2px solid #21283200;
    border-image: linear-gradient(to right, #212832 0%, #212832 100%);
    border-image-slice: 1;
    position: relative;
    margin: 1rem 3rem 1rem 1rem;
    clear: both;
    color: #fffefe;
    border-radius: 10px;
 }
  .timeline__item:after, .timeline__item:before {
    content: '';
    position: absolute;
 }
  .timeline__item:before {
    right: -10px;
    top: calc(50% - 5px);
    border-style: solid;
    border-color: #212832 #212832 transparent transparent;
    border-width: 10px;
    transform: rotate(45deg);
 }
  @media screen and (min-width: 700px) {
    .timeline__item {
      width: 44%;
      margin: 1rem;
   }
    .timeline__item:nth-of-type(2n) {
      float: right;
      margin: 1rem;
      border-image: linear-gradient(to right, #212832 0%, #212832 100%);
      border-image-slice: 1;
   }
    .timeline__item:nth-of-type(2n):before {
      right: auto;
      left: -10px;
      border-color: transparent transparent #212832 #212832;
   }
 }
  .timeline__item--year {
    text-align: center;
    max-width: 150px;
    margin: 0 48px 0 auto;
    font-size: 1.8rem;
    background-color: #090e16;
    line-height: 1;
    color: #ffffff;
    border-image: none;
    padding: 0.5rem 1rem 1rem;
 }
  .timeline__item--year:before {
    display: none;
 }
  @media screen and (min-width: 700px) {
    .timeline__item--year {
      text-align: center;
      margin: 0 auto;
   }
    .timeline__item--year:nth-of-type(2n) {
      float: none;
      margin: 0 auto;
      border-image: none;
   }
    .timeline__item--year:nth-of-type(2n):before {
      display: none;
   }
 }
  .timeline__title {
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-size: 1.5em;
 }
  .timeline__blurb {
    line-height: 1.5;
    font-size: 1rem;
    margin: 0.5rem 0 0;
 }

 .hvga6{
  background-color: #ffffff00; /* For browsers that do not support gradients */
  height: 50vh;
  width: 100%;
  display: grid;
grid-template-columns: 100%;
 
background-repeat: no-repeat;
background-position: center right;
background-size: 20%;
border:1px #212832 solid;
text-align: left;
}
.hvga6a{
height: 50%;
width: 95%;
margin: auto;
display: grid;



border-radius: 5px;
}
.hvga6a h1{
  color: #474747;
  font-size: 2.2vh;
}
.hvga6a h1 span{
  color: #000000;
  font-size: 5vh;
  font-weight: bold
}

.hvga7 {
  background-color: #000000; /* For browsers that do not support gradients */
  height: min-content;
  width: 100%;
  display: grid;
  grid-template-areas: 
  'title title title'
;
grid-template-columns: 33% 33% 33%;
grid-template-rows: 25%;
  align-items: center;
  border: 1px #212832 solid;
  padding-top: 2vh;
  padding-bottom: 2vh;
}
.hvga7 h2{
  height: min-content;
  width: auto;
  text-align: center;
  grid-area: title;
  font-size: 4vh;
  color: rgb(0, 0, 0);
margin: auto auto;
margin-bottom: 4vh;
}
.hvga7 div{
  height: 15vh;
  width: auto;
  background-color: #000;
  border-radius: 10px;

  margin: auto auto;
  border: 1px #212832 solid;
  padding: 2%;
  display: grid;
  cursor: pointer;
}
.hvga7 div h1{
  color: #000000;
  font-size: 2.2vh;
  text-align: center;
  height: min-content;
  width: 50vh;
  margin: auto;
  display: grid;
  grid-template-columns: 10vh auto;
}
.hvga7 div h1 span{
  color: #000000;
  font-size: 3vh;
  height: min-content;
  margin: auto;

}
.bsc{
  height:10vh;
  width: 10vh;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin: auto;
}
.certik{
  height:10vh;
  width: 10vh;
 
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin: auto;
}
.sidemenu{
  display: grid;
}

.hub{
  grid-template-columns: auto;
}

  @media screen and (max-width: 1000px) {
  .sidemenu{
    display: none;
  }
  .hub{
    grid-template-columns: 100%;
  }
    .hvga1{
    
      background-color: #000000;
      height: 100vh;
      width: 100%;
      display: grid;
      grid-template-areas: 
      'logo itensm'
      'sub1 sub1'
      'sub1 sub1'
      'sub1 sub1 ';
    
      align-items: center;
    }
    @keyframes movingGradient {
      from {background-position: 0 0;}
      to {background-position:100% 100%;}
    }
    .logo1{
    grid-area: logo;
    position: absolute;
    top: 20%;
    height: 10vh;
    width: 10vh;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    
    }
    .sub1{
      position: absolute;
      left: 5%;
      top: 50%;
      grid-area: sub1;
    height:20%;
    width: auto;
    text-align: left;
    margin: auto auto;
    font-weight: bold;
    font-size: 2.2vh;
    color: rgb(0, 0, 0);
  
    }
    .sub1 span{
      grid-area: sub;
    height: min-content;
    width: auto;
    text-align: center;
    font-weight: bold;
    margin: auto auto;
    font-size: 5vh;
    color: rgb(0, 0, 0);
    
    }
    .sub1 ul{
      display: flex;
    }
    .sub1 ul li{
      height: 6vh;
      width: 18vh;
      background-color: #212832;
      border-radius: 10px;
      margin-top: auto;
      margin-bottom: auto;
    border: none;
    font-size: 2.0vh;
    cursor: pointer;
    display: grid;
    margin-right: 2%;
    }
    .sub1 ul li a{
  height: min-content;
  width: auto;
  margin: auto auto;
      text-decoration: none;
      color: rgb(0, 0, 0);
    }
    .redes{
      position: absolute;
      top: 85%;
      left: 1%;
      width: 30vh;
      height: 10vh;
      margin-bottom: 10vh;
      display: flex;
    grid-area: redes;
    }
    
    .redes a{
    height: min-content;
    width:min-content;
    margin: auto;
    border: none;
    text-decoration: none;
    
    }
    .instagram{
  
      width:7vh;
      height:7vh;
      margin: auto auto;
      background-repeat: no-repeat;
      background-size:40%;
      background-position: center;
      padding: 1%;
      border-radius: 45px;
      cursor: pointer;
    }
    .telegram{
    

      width:7vh;
      height:7vh;
      margin: auto auto;
      background-repeat: no-repeat;
      background-size:40%;
      background-position: center;
    
      padding: 1%;
      border-radius: 45px;
      cursor: pointer;
    }
    .whatsapp{

      width:7vh;
      height:7vh;
      margin: auto auto;
      background-repeat: no-repeat;
      background-size:55%;
      background-position: center;
    
      padding: 1%;
      border-radius: 45px;
      cursor: pointer;
    }
    .discord{
      display: none;

      width:7vh;
      height:7vh;
      margin: auto auto;
      background-repeat: no-repeat;
      background-size:40%;
      background-position: center;
    
      padding: 1%;
      border-radius: 45px;
      cursor: pointer;
     
    
    }
    .twiter{

      width:7vh;
      height:7vh;
      margin: auto auto;
      background-repeat: no-repeat;
      background-size:40%;
      background-position: center;
    
      padding: 1%;
      border-radius: 45px;
      cursor: pointer;
    }
    .facebook{
     
      width:7vh;
      height:7vh;
      margin: auto auto;
      background-repeat: no-repeat;
      background-size:27%;
      background-position: center;
    
      padding: 1%;
      border-radius: 45px;
      cursor: pointer;
    }
    
    .menui1{
      position: absolute;
      top: 10%;
      right: 7%;
      width: 10vh;
    height: 7vh;
    
    display: grid;
    grid-template-columns: min-content min-content min-content;
    }
    .menui1 li{
      width: 14vh;
    height: min-content;
    padding: 2vh;
    display: flex;
    margin: auto auto;
    background-color: #000000;
    border-radius: 10px;
    }
    .menui1 li a{
      width: auto;
    height: min-content;
    font-size: 2vh;
    margin: auto;
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    }
    .menui1 li span{
      width: auto;
    height: min-content;
    font-size: 2vh;
    margin: auto;
    color: white;
    font-weight: bold;
    }
  
    .footercr{
      height: 10vh;
      font-size: 2.5vh;
      margin-bottom: 10vh;
      color: rgb(92, 92, 92);
      text-align: center;
      }
      .footercr span{
      
          font-size: 2vh;
          color: rgb(0, 0, 0);
          }
  
    .hvga2{
      background-color: #ffffff00; /* For browsers that do not support gradients */
      height: 125vh;
      width: 100%;
      display: grid;
    grid-template-columns: 100%;
      align-items: center;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100vh;

    }
    .lala{
      background-position: bottom right;
    }
    .hvga2a{
  height: 50%;
  width: 95%;
  margin: auto;
  display: grid;
  white-space: normal;
  padding: 2%;
  
  border-radius: 5px;
    }
    .hvga2a h1{
      color: #525252;
      font-size: 2.2vh;
    }
    .hvga2a h1 span{
      color: #000000;
      font-size: 5vh;
      font-weight: bold
    }
    
  
    .hvga3 {
      background-color: #212832; /* For browsers that do not support gradients */
      height: 150vh;
      width: 100%;
      display: grid;
      grid-template-areas: 
      'title title'
   ;
    grid-template-columns: 50%;
      align-items: center;
      background-image: linear-gradient(350deg, #212832 45%, #000000 1%);
    }
    .hvga3 div{
      height: 39vh;
      width: 90%;
      background-color: #000;
      border-radius: 10px;
   
      margin: auto auto;
      padding: 2%;
      display: grid;
    }
    
    .hvga3 div h1{
      color: #000000;
      font-size: 2.2vh;
      text-align: center;
      height: min-content;
      width: 80%;
      margin: auto;
    }
    .hvga3 div h1 span{
      color: #000000;
      font-size: 4vh;
  
  
    }
  
    .hvga4{
      background-color: #212832; /* For browsers that do not support gradients */
      height: 100vh;
      width: 100%;
      display: grid;
      grid-template-areas: 
      'title'
   ;
      align-items: center;
      background-image: linear-gradient( #000000 45%, #000000 1%);
    }
    .hvga4 h2{
      height: min-content;
      width: auto;
      text-align: center;
      grid-area: title;
      font-size: 4vh;
      color: rgb(0, 0, 0);
  margin: auto auto;
  
    }
    .hvga4 div{
      height: min-content;
      width: 90%;
      background-color: #000;
      border-radius: 10px;
      margin: auto auto;
      border: 2px #212832 solid;
      padding: 2%;
      display: grid;
    }
    .hvga4 div h1{
      color: #000000;
      font-size: 3vh;
      text-align: left;
      height: min-content;
      width: 100%;
      margin: auto;
    }
    .hvga4 div h1 span{
      color: #000000;
      font-size: 3vh;
    }
  

    .hvga6{
      background-color: #ffffff00; /* For browsers that do not support gradients */
      height: 50vh;
      width: 100%;
      display: grid;
    grid-template-columns: 100%;
    
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 20%;
    border:1px #212832 solid;
    text-align: left;
    }
    .hvga6a{
    height: 50%;
    width: 95%;
    margin: auto;
    display: grid;
    
    
    
    border-radius: 5px;
    }
    .hvga6a h1{
      color: #000000;
      font-size: 2.2vh;
    }
    .hvga6a h1 span{
      color: #000000;
      font-size: 4vh;
      font-weight: bold
    }
    .timeline {
      position: relative;
      padding: 1rem;
      margin: 0 auto;
      max-width: 1300px;
      width: 90%;
   }
  }

  @media screen and (max-width: 600px) {
    .sub1 ul li{
      height: 6vh;
      width: 16vh;
      background-color: #212832;
      border-radius: 10px;
      margin-top: auto;
      margin-bottom: auto;
    border: none;
    font-size: 2.0vh;
    cursor: pointer;
    display: grid;
    margin-right: 2%;
    }
    .hvga2{
      background-color: #ffffff00; /* For browsers that do not support gradients */
      height: 125vh;
      width: 100%;
      display: grid;
    grid-template-columns: 100%;
      align-items: center;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 150%;

    }
    .lala{
      background-position: bottom right;
    }
    .hvga2a{
  height: 50%;
  width: 95%;
  margin: auto;
  display: grid;
  white-space: normal;
  padding: 2%;
  
  border-radius: 5px;
    }
    .hvga2a h1{
      color: #000000;
      font-size: 2.2vh;
    }
    .hvga2a h1 span{
      color: #000000;
      font-size: 5vh;
      font-weight: bold
    }
    
    .hvga3 {
      background-color: #212832; /* For browsers that do not support gradients */
      height: 150vh;
      width: 100%;
      display: grid;
      grid-template-areas: 
      'title'
   ;
    grid-template-columns: 100%;
      align-items: center;
      background-image: linear-gradient(350deg, #212832 45%, #000000 1%);
    }
    .hvga3 div{
      height: 39vh;
      width: 80%;
      background-color: #000;
      border-radius: 10px;
   
      margin: auto auto;
      padding: 2%;
      display: grid;
    }
    
    .hvga4 div h1{
      color: #000000;
      font-size: 2vh;
      text-align: left;
      height: min-content;
      width: 100%;
      margin: auto;
    }
    .hvga4 div h1 span{
      color: #000000;
      font-size: 2vh;
    }
  
    .hvga6{
      background-color: #ffffff00; /* For browsers that do not support gradients */
      height: 50vh;
      width: 100%;
      display: grid;
    grid-template-columns: 100%;
   
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 20%;
    border:1px #212832 solid;
    text-align: left;
    }
    .hvga6a{
    height: 50%;
    width: 100%;
    margin: auto;
    display: grid;
    
    
    
    border-radius: 5px;
    }
    .hvga6a h1{
      color: #585858;
      font-size: 2.2vh;
    }
    .hvga6a h1 span{
      color: #000000;
      font-size: 3vh;
      font-weight: bold
    }
    .timeline {
      position: relative;
      padding: 1rem;
      margin: 0 auto;
      max-width: 1300px;
      width: 98%;
   }
  }
.cardhubdvn
{
height: 100vh;
width: 100%;
display: grid;
grid-template-rows: 100%;
margin: auto auto;
margin-bottom: 2vh;
overflow: hidden;
}

.cardhubdvn video
{
  object-fit: cover;

height: 100vh;
width: 100%;
margin: auto auto;
display: grid;
grid-template-columns: 100%;

}

.Whitepaper{
  height: 100%;
  width: 100%;
  align-items: center;
  text-align: center;
  margin: auto auto;
}
.Whitepaper iframe{
  width: 100%;
  height: 100vh;
  border: 0px;
}
.Whitepaper object h1{
  height: min-content;
  width: 100%;
  color: #000000;
  
}
.Whitepaper object p{
  height: 8vh;
  margin: auto auto;
  width: 20vh;
background-color: #212832;
border-radius: 10px;
display: grid;
  
}
.Whitepaper object p a{
  height: min-content;
  width: auto;
  color: #000000;
  text-decoration: none;
  font-size: 2.2vh;
  margin: auto;
}

  /* width */
  ::-webkit-scrollbar {
    width: 1vh;
    height: 1vh;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(247, 246, 246);
    border-radius: 10px;
  }