* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;

}

body {
  width: 100%;
  background: linear-gradient(to bottom right, #161a1e, #161a1e);
  background-repeat: no-repeat;
  display: grid;
}

.App {
  width: 100%;
  height: min-content;
  display: grid;

  /*background-color: #f2f2f2fe;
  background: linear-gradient(ellipse at bottom, #fdfdfd 0%, #bebebe 100%);*/
  ;
}

.menuitem {

}

.itemtohover:hover {}

@keyframes example {
  from {
    display: none;
  }

  to {
    display: block;
  }
}

.onItemHover {
  display: none;

}

.menuitem:hover .onItemHover {
  display: grid;
}

.inpult-primary {
  background: #00000050;
  border-radius: 10px;
  border: 1px solid #ffffff10;
  color: #fff;
  width: 90%;
  height: 35px;
  cursor: pointer;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradienta {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.Home {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;

}

.a {
  height: 8vh;
  width: 100%;
  margin: auto;
  display: grid;
  align-items: center;
  grid-template-columns: 10vh 25vh;
  border-radius: 10px;


}

.logo {

  height: 8vh;
  width: 8vh;

  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;

}

.sub {
  left: 10vh;
  height: min-content;
  width: auto;
  text-align: left;
  margin: auto auto;
  font-size: 1.4vh;

  color: rgb(0, 0, 0);

  display: none;
}

.sub span {
  grid-area: sub;
  height: min-content;
  width: 100%;
  text-align: center;
  margin: auto auto;
  font-size: 2.2vh;



}

.sub h2 {
  height: min-content;
  width: 100%;
  text-align: left;
  font-size: 1.2vh;
  font-weight: bold;
  color: rgb(0, 0, 0);


}

.menui {
  position: absolute;
  left: 0.2%;
  width: 8vh;
  top: 0%;
  height: 100%;

  display: inline;
  grid-template-columns: 100%;
  background-color: #00000000;
  border: 1px solid #1a263550;
  border-radius: 10px;
  ;
  z-index: 1;
  grid-area: itensm;

}

.menui li {
  width: 8vh;
  height: 10vh;
  display: grid;
  margin: auto auto;
  cursor: pointer;
}

.menui li:hover {
  width: 8vh;
  height: 10vh;
  display: grid;
  margin: auto auto;
  background-color: #1f2d3f;
  border-radius: 10px;
  cursor: pointer;
}

.menui li svg {

  margin: auto auto;
  height: 50%;
  width: 50%;
}

.menui li .dropdown-content {

  position: absolute;
  left: 8vh;
  top: auto;
  bottom: auto;
  border: 1px solid #1a263550;
  border-radius: 10px;
  height: 10vh;
  width: 30vh;
  margin: auto auto;
  display: grid;

}

.menui li .dropdown-content a {
  height: min-content;
  width: 100%;
  color: rgb(255, 255, 255);
  padding-left: 1vh;
  text-decoration: none;
  font-size: 3vh;
  text-align: left;
  border-radius: 10px;
  align-items: center;
  display: block;

}

.dropdown-content a:hover {
  background-color: #161f2c00;
}

.menui li .dropdown-content {
  display: none;
}

.menui li:hover .dropdown-content {
  display: grid;
}

.menui li .dropdown-content2 {

  position: absolute;
  left: 8vh;
  top: 25%;
  bottom: 25%;
  background-color: #1a2635;
  border: 1px solid #1a263550;
  border-radius: 10px;
  height: 30vh;
  width: 30vh;
  margin: auto auto;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 50% 50%;
  color: rgb(0, 0, 0);
  font-size: 2.2vh;
  align-items: right;
  text-decoration: none;
  text-align: center;
  font-family: 'Permanent Marker', cursive;
}

.menui li .dropdown-content2 div {
  height: 100%;
  width: 100%;
  right: 0%;
  display: grid;
  grid-template-columns: 33% 33% 33%;

}

.menui li .dropdown-content2 div a {
  height: min-content;
  width: min-content;
  margin: auto;
  border: none;
  text-decoration: none;
  display: grid;

}

.roullet {

  width: 5vh;
  height: 5vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
}

.instagram {

  width: 10vh;
  height: 10vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
  display: none;
}

.telegram {


  width: 10vh;
  height: 10vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;

  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
}

.whatsapp {

  width: 7vh;
  height: 7vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: center;

  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
}

.discord {
  display: none;

  width: 7vh;
  height: 7vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;

  padding: 1%;
  border-radius: 45px;
  cursor: pointer;


}

.twiter {

  width: 10vh;
  height: 10vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;

  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
}

.facebook {

  width: 7vh;
  height: 7vh;
  margin: auto auto;
  background-repeat: no-repeat;
  background-size: 27%;
  background-position: center;

  padding: 1%;
  border-radius: 45px;
  cursor: pointer;
}



.menui li .dropdown-content2 {
  display: none;
}

.menui li:hover .dropdown-content2 {
  display: grid;
}


.menui li a {
  width: auto;
  height: min-content;
  font-size: 2vh;
  margin: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

.logow {

  height: 4vh;
  width: 4vh;

  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;

}

.logon {
  grid-area: logon;
  height: 10vh;
  width: 10vh;

  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;

}

.b {

  height: 100vh;
  width: 100%;
  display: grid;


}

@media screen and (max-width: 600px) {

  .a {
    height: 8vh;
    width: 100%;
    margin: auto;

    display: grid;
    align-items: center;
    grid-template-columns: 5vh 25vh;

    border-radius: 10px;


  }

  .logo {

    height: 8vh;
    width: 8vh;
    background-size: 8vh;
    background-position: center top;
    background-repeat: no-repeat;

  }

  .sub {
    left: 8vh;
    height: min-content;
    width: auto;
    text-align: left;
    margin: auto auto;
    font-size: 1.4vh;
    font-weight: bold;
    color: rgb(0, 0, 0);


  }

  .sub span {
    grid-area: sub;
    height: min-content;
    width: 100%;
    text-align: center;
    margin: auto auto;
    font-size: 2vh;
    color: rgb(0, 0, 0);


  }

  .sub h2 {
    height: min-content;
    width: 100%;
    text-align: left;
    font-size: 1.2vh;
    font-weight: bold;
    color: rgb(0, 0, 0);


  }

}


.divsb {
  position: relative;
  right: 5vh;
  top: 10%;
  bottom: 10%;

  display: grid;
  height: 80%;
  width: 100%;
  margin: auto auto;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: hidden;

}




/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 1vh;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(247, 246, 246);
  border-radius: 10px;
}