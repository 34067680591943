* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

/* #3c4042 */
.farms {
  height: min-content;
  width: 100%;
  margin: auto;
  display: grid;

}

#progress-spinner {
  border-radius: 50%;
  height: 270px;
  width: 270px;
}

#middle-circle {
  position: absolute;
  border-radius: 50%;
  height: 220px;
  width: 220px;
  background-color: #1b1b1b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

}

.stage {
  border-bottom: 3px solid #444;
  display: flex;
  height: 330px;
  width: 100%;
}

.box {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  background-color: #F44336;
  height: 200px;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 200px;
}

.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: ease;
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.par {
  grid-template-columns: 25% 25% 25% 25%;
}

.iconsc {
  background-size: 50%;
}

@media screen and (max-width: 1000px) {
  .widtha {
    width: 75%;
  }
}

@media screen and (max-width: 720px) {
  .widtha {
    width: 90%;
  }

  .grida {
    grid-template-columns: 50% 50%;
  }

  .par {
    grid-template-columns: 50% 50%;
  }

  .iconsc {
    background-size: 75%;
  }
}

@media screen and (max-width: 600px) {
  .widtha {
    width: 100%;
  }

  .grida {
    grid-template-columns: 100%;
  }

  .par {
    grid-template-columns: 100%;
  }

  .iconsc {
    background-size: 95%;
  }
}

.logosub {
  height: 10vh;
  display: grid;
  grid-template-columns: 10vh auto;
}

.nftmenu {
  height: 12vh;
  width: 50vh;
  grid-template-rows: 80% 20%;
  display: grid;
  color: #000000;
}


.loading-box {
  position: absolute;
  width: 20%;
  height: 2vh;
  top: 25%;
  border-radius: 50px;
  border: 2px solid #ededed;
  overflow: hidden;
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50px;
  background: linear-gradient(45deg, #b6b5ff, #ff9797);
  left: 0%;
}

.loading {
  position: absolute;
  margin-left: 5vh;
  animation: load 0.2s linear infinite;
  font-size: 3vh;
  color: "#ffffff"
}

@keyframes load {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.logo1 {

  height: 8vh;
  width: 8vh;

  background-size: 8vh;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;

}

.sub1 {
  left: 12vh;
  height: min-content;
  width: auto;
  text-align: left;
  margin: auto auto;
  margin-left: 0px;
  font-size: 1.5vh;
  font-weight: bold;
  color: rgb(255, 255, 255);


}

.sub1 span {
  grid-area: sub;
  height: min-content;
  width: 100%;
  text-align: center;
  margin: auto auto;
  font-size: 2.5vh;
  color: rgb(255, 255, 255);


}

.sub1 h2 {
  height: min-content;
  width: 100%;
  text-align: left;
  font-size: 1.2vh;
  font-weight: bold;
  color: rgb(0, 0, 0);


}

.logoto {

  height: 2.5vh;
  width: auto;
  margin: auto 0;

  background-size: 5vh;
  background-position: center left;
  background-repeat: no-repeat;


}

#onlinebut {
  border-bottom: 1px solid #ffffff;
}

.a {
  grid-area: menus;
  /*border-bottom: 0.05px solid #ffffff20;*/
  border-radius: 0px;
}

.me {
  position: absolute;
  right: 24%;
  left: 24%;
  width: 28vh;
  height: 7vh;
  display: grid;
  grid-template-columns: 7vh 7vh 7vh 7vh;
  grid-template-rows: 100%;
  margin: auto auto;
  border-radius: 10px;
  cursor: pointer;
  color: rgb(0, 0, 0);

  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
}


.me li {
  width: 7vh;
  height: 7vh;
  display: grid;
  margin: auto auto;
  cursor: pointer;
}

.me li svg {

  margin: auto auto;
  height: 50%;
  width: 50%;
}


.meme {
  width: 10vh;
  height: 70vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 7vh 7vh 7vh;
  margin: auto auto;
  border-radius: 10px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
}


.meme li {
  width: 7vh;
  height: 7vh;
  display: grid;
  margin: auto auto;
  cursor: pointer;
}

.meme li:hover {
  width: 7vh;
  height: 7vh;
  display: grid;
  margin: auto auto;
  /*background-color: #1f2d3f;*/
  border-radius: 10px;
  cursor: pointer;
}

.meme li svg {

  margin: auto auto;
  height: 50%;
  width: 50%;
}

.svglivre {
  width: 10vh;
  height: 10vh;
  display: grid;
  margin: auto auto;
  cursor: pointer;
  height: 70%;
  width: 70%;
  color: black;
}

.Wallet {

  width: 100%;
  padding: 7px;
  display: grid;
  margin: auto;

  grid-template-columns: auto;
  border-radius: 5px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: #00000020;
  border: 0.1px solid #ffffff20;
}

.Wallet svg {
  display: none;
}

.Wallet h1 {
  height: min-content;
  width: 100%;
  font-size: 10px;
  margin: auto;
  text-align: center;
  overflow: hidden;
}

.whiteHat {
  border: none;

  height: 50px;
  width: 50px;
  transform: rotate(22.5deg);
  color: rgb(0, 0, 0);
  font-size: 55px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

.fa-location-arrow {
  transform: rotate(-45deg);
  font-size: 3vh;
  color: white;
}

.whiterock {
  height: 5vh;
  width: 5vh;
  transform: rotate(15.5deg);
  color: rgb(0, 0, 0);
  font-size: 4vh;
  margin: auto auto;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

.whiterock2 {
  height: 5vh;
  width: 5vh;

  color: rgba(0, 0, 0, 0);
  font-size: 4vh;
  margin: auto auto;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

.infos {
  height: 10vh;
  width: 95%;
  margin: auto;
  margin-top: 1vh;
  grid-area: infos;
  display: grid;
  grid-template-areas: 'pp bal';

  /*border-bottom: 0.05px solid #ffffff20;*/
}

.titlegame {
  height: min-content;
  color: white;
  margin: auto;
  text-align: left;
  font-size: 5vh;
  font-weight: bold;
  display: flex;
  margin-left: 0vh;
  grid-area: pp;
}

.crt {
  height: 60vh;
  width: 99%;
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  align-items: center;
  text-align: center;
  display: grid;
}

.crt h1 {

  height: min-content;
  width: 50%;
  margin: auto;
  font-size: 10vh;
  color: white;
  cursor: pointer;
  /*animation-name: shake;
    animation-duration: 1s;
    transform-origin: 50% 50%;
    animation-iteration-count: infinite;*/
}

.crt h1 span {
  display: inline-block;
  position: absolute;
  height: min-content;
  width: 50%;
  font-size: 10vh;
  color: white;

}

.crt h1 svg {
  height: 15%;
  width: 15%;
}

@keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

.crt rect {
  fill: #3c404200
}

.balances {
  width: 35vh;
  height: 10vh;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  margin: auto auto;
  cursor: pointer;
  color: white;
  margin-right: 0vh;
  grid-area: bal;
}

.bnbbal {
  height: min-content;
  width: 95%;
  margin: auto;
  background-color: #090a0f10;
  border-radius: 10px;

}

.bnbbal div {

  height: 4vh;
  width: 100%;
  font-size: 1.5vh;
  display: grid;
  grid-template-columns: 4vh auto;
  text-align: center;
  color: #f3f3f3;
}

.bnbbal div i {

  height: 4vh;
  width: 100%;
  background: url("https://bscscan.com/token/images/binance_32.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  border-radius: 45px;

}

.bnbbal p {
  height: min-content;
  width: 100%;
  font-size: 2vh;
  margin: auto;
  text-align: center;
  color: #8b8b8b;
  overflow: hidden;

}

.coinbal {
  height: min-content;
  width: 95%;
  margin: auto;
  background-color: #090a0f10;
  border-radius: 10px;

}

.coinbal div {

  height: 4vh;
  width: 100%;
  font-size: 1.5vh;
  display: grid;
  grid-template-columns: 4vh auto;
  text-align: center;
}

.coinbal div i {

  height: 4vh;
  width: 100%;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 45px;


}

.coinbal p {
  height: min-content;
  width: 100%;
  margin: auto;
  font-size: 2vh;
  text-align: center;
  overflow: hidden;
}

.bets {
  grid-area: bets;
  height: 50vh;
  width: 95%;
  margin: auto;

  display: grid;
  grid-template-rows: 10vh 90%;


}

.bets p {

  height: min-content;
  width: 95%;
  margin: auto;
  color: white;
  font-size: 3vh;

}

.bets p span {

  height: min-content;
  width: 100%;
  margin: 0;
  color: rgb(224, 224, 224);
  font-size: 1.5vh;

}

.bets ul {

  height: 100%;
  width: 100%;
  margin: auto;
  display: block;
  align-items: center;
  overflow: auto;

}

.bets ul li {
  height: 10vh;
  width: 95%;
  margin: auto;
  display: grid;
  grid-template-columns: 40% 10% 20% 10% 20%;
  align-items: center;
  text-align: center;

}

.bets ul li h1 {
  height: min-content;
  width: 90%;
  margin: auto;
  color: #b1b1b1;
  font-size: 2.2vh;
  overflow: hidden;
}

.bets ul li h2 {

  height: min-content;
  width: 100%;
  margin: auto;
  color: white;
  font-size: 2.5vh;
  overflow: hidden;



}

.bets ul li h3 {
  height: min-content;
  width: 90%;
  margin: auto;
  color: #b1b1b1;
  font-size: 2.2vh;
  overflow: hidden;
}

.bets ul li h4 {
  height: min-content;
  width: 100%;
  margin: auto;
  color: white;
  font-size: 2.5vh;
  overflow: hidden;

}

.bets ul li h5 {
  height: min-content;
  width: 90%;
  margin: auto;
  color: #b1b1b1;
  font-size: 2.2vh;
  overflow: hidden;
}

.bet {

  height: min-content;
  width: 80%;
  margin-top: auto;
  margin-bottom: auto;
  border-left: 1px solid #ffffff20;
  padding: 2vh;
  align-items: center;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

.bet p {
  text-align: left;
  font-size: 15px;
  color: white;
  width: 100%;
}

.bet p span {
  text-align: center;
  font-size: 15px;
  color: rgb(0, 255, 34);
  width: 100%;
}

.bet h1 {
  width: 95%;
  text-align: right;
  font-size: 15px;
  color: white;
  width: 100%;
}

.bet h1 span {
  text-align: right;
  font-size: 15px;
  color: rgb(0, 255, 34);
  width: 100%;
}

.bet div div {
  height: 55px;
  width: 98%;
  margin: auto;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 15% 70% 15%;
  border: 1.5px solid #ffffff50;
}

.bet div ul {
  height: 70px;
  width: 95%;
  margin: auto;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 15.8% 15.8% 15.8% 15.8% 15.8% 15.8%;
  list-style-type: none;
  text-align: center;
}

.bet div ul li {
  cursor: pointer;
  text-decoration: none;
  padding: 1vh;

}

.bet div div input {
  height: 100%;
  width: 100%;
  margin: auto;
  background-color: #ffffff00;
  font-size: 15px;
  border-radius: 10px;
  color: white;
  border: none;
  outline: none;
  text-align: right;
  -moz-appearance: textfield;
}

.bet div div p {
  text-align: center;
  height: min-content;
  width: auto;
  margin: auto;
  font-size: 15px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
}

.bet button {
  height: 55px;
  width: 98%;
  margin: auto;
  background-color: rgb(0, 202, 27);
  border-radius: 10px;
  font-weight: bold;
  color: white;
  display: grid;
  text-align: center;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 5vh;
  border: none;
  outline: none;
  cursor: pointer;
}

.bet div button {
  height: 55px;
  width: 98%;
  margin: auto;

  background-color: rgb(202, 0, 0);
  border-radius: 10px;
  font-weight: bold;
  color: white;
  display: grid;
  text-align: center;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 5vh;
  border: none;
  outline: none;
  cursor: pointer;
}


.Trademainboletamodal2 {

  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  align-items: center;
  background-color: #121212;
  display: grid;

}

.Trademainboletamodal2 iframe {
  position: relative;
  display: grid;
  margin: auto;
  border-radius: 10px;
  background-color: #0e151d;
  border: 1px solid #ffffff05;
  box-shadow: 2px 2px 2px 2px #00000010;
  height: 70vh;
  width: 50vh;
}

.Trademainboletamodal2 h1 {
  font-size: 3vh;
  text-align: center;
  color: #dadada;
  margin: auto;
}

.Trademainboletamodal2 h2 {
  font-size: 2vh;
  text-align: center justify;
  width: 90%;
  margin: auto;

  color: #dadada;
}

.Trademainboletamodal2 .fa-rocket {
  width: 70%;
  height: 14vh;
  align-items: center;
  margin: auto auto;
  color: rgb(255, 255, 255);
  font-size: 12vh;
  text-align: center;

}

.buttontx {
  width: 94%;
  height: 8vh;
  margin: auto auto;
  border-radius: 10px;
  border: 0.1px solid #ffffff20;
  background-color: #00000000;
  color: #ffffff;
  align-items: center;
  font-size: 2.4vh;

  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.foo {
  grid-area: foot;
  height: min-content;
  width: 100%;

  display: grid;
  margin-top: 5vh;

}

.fooctt {
  display: grid;
  grid-template-columns: 60% 40%;
  width: 100%;

}

.ctt1 {
  height: 100%;
  width: 90%;
  margin: auto;
  text-align: justify;
  color: #f8f8f8;
  font-size: 2.2vh;

  padding-right: 2vh;
}

.ctt2 {
  height: min-content;
  width: 100%;
  display: flex;
  margin: auto;

}

.poocoin {
  height: 10vh;
  width: 10vh;
  background-color: #212832;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-right: 2%;

  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.pancake {
  height: 10vh;
  width: 10vh;
  background-color: #212832;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-right: 2%;

  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.bsc {
  height: 10vh;
  width: 10vh;
  background-color: #212832;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-right: 2%;

  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.poocoin1 {
  height: 10vh;
  width: 10vh;
  background-color: #212832;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-right: 2%;

  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.pancake1 {
  height: 10vh;
  width: 10vh;
  background-color: #212832;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-right: 2%;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.bsc1 {
  height: 10vh;
  width: 10vh;
  background-color: #212832;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-right: 2%;

  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.rcr {
  height: 10vh;
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto;
  grid-area: bau;
}

.rcr p {
  color: white;
  width: 100%;
  height: min-content;
  margin: auto;
  text-align: right;
  font-size: 2vh;
}

@media screen and (max-width: 1000px) {
  .farms {
    height: min-content;
    width: 100%;
    margin: auto;

  }

  .crt h1 {

    height: min-content;
    width: 100%;
    font-size: 9vh;
    color: white;
    cursor: pointer;
    /*animation-name: shake;
        animation-duration: 1s;
        transform-origin: 50% 50%;
        animation-iteration-count: infinite;*/
  }

  .crt h1 span {
    display: inline-block;
    height: min-content;
    width: 50%;
    font-size: 10vh;
    color: white;

  }

  .crt h1 svg {
    height: 15%;
    width: 15%;
  }

  .bet {

    height: min-content;
    width: 100%;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    border-left: 1px solid #ffffff00;

    align-items: center;
    display: grid;
    grid-template-rows: min-content min-content min-content;
  }
}

@media screen and (max-width: 600px) {
  .me {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 8vh;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 100%;
    background-color: #101217;
    margin: auto auto;
    border-radius: 0px;
    cursor: pointer;
    color: rgb(255, 255, 255);

    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
  }

  .foo {
    grid-area: foot;
    height: min-content;
    width: 100%;

    display: grid;
    margin-top: 5vh;

  }

  .fooctt {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 10vh;
    width: 100%;

  }

  .ctt1 {
    height: 100%;
    width: 100%;
    margin: auto;
    text-align: justify;
    color: #f5f5f5;
    font-size: 2.2vh;
  }

  .ctt2 {
    height: min-content;
    width: min-content;
    display: flex;
    margin: auto;

  }

  .rcr {
    height: 10vh;
    width: 100%;
    display: grid;
    grid-template-rows: min-content min-content;
    grid-template-columns: 100%;
    grid-area: bau;
  }

  .rcr p {
    color: rgb(201, 201, 201);
    width: 100%;
    height: min-content;
    margin: auto;
    text-align: center;
    font-size: 1.5vh;
  }

  .farms {
    height: min-content;
    width: 100%;
    margin: auto;

    display: grid;

  }

  .infos {
    height: min-content;
    width: 95%;
    margin: auto;
    margin-top: 1vh;
    grid-area: infos;
    display: grid;
    grid-template-areas: 'pp pp'
      'bal bal';

    /*border-bottom: 0.05px solid #ffffff20;*/
  }

  .titlegame {
    height: min-content;
    color: white;
    margin: auto;
    text-align: center;
    font-size: 3vh;
    font-weight: bold;
    display: flex;

    grid-area: pp;
  }

  .crt {
    height: 60vh;
    width: 99%;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    align-items: center;
    text-align: center;
    display: grid;

  }

  .crt h1 {


    height: min-content;
    width: 100%;
    font-size: 7vh;
    color: white;
    cursor: pointer;
    /*animation-name: shake;
        animation-duration: 1s;
        transform-origin: 50% 50%;
        animation-iteration-count: infinite;*/
  }

  .crt h1 svg {
    height: 15%;
    width: 15%;
  }

  .crt h1 span {
    display: inline-block;

    height: min-content;
    width: 50%;
    font-size: 10vh;
    color: white;

  }

  .balances {
    width: 35vh;
    height: 10vh;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    margin: auto auto;
    cursor: pointer;
    color: white;

    grid-area: bal;

  }

  .bnbbal {
    height: min-content;
    width: 95%;
    margin: auto;
    background-color: #090a0f10;
    border-radius: 10px;

  }

  .bnbbal div {

    height: 4vh;
    width: 100%;
    font-size: 1.5vh;
    display: grid;
    grid-template-columns: 4vh auto;
    text-align: center;
    color: #f3f3f3;
  }

  .bnbbal div i {

    height: 4vh;
    width: 100%;
    background: url("https://bscscan.com/token/images/binance_32.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 45px;

  }

  .bnbbal p {
    height: min-content;
    width: 100%;
    font-size: 2vh;
    margin: auto;
    text-align: center;
    color: #8b8b8b;
    overflow: hidden;

  }

  .coinbal {
    height: min-content;
    width: 95%;
    margin: auto;
    background-color: #090a0f10;
    border-radius: 10px;

  }

  .coinbal div {

    height: 4vh;
    width: 100%;
    font-size: 1.5vh;
    display: grid;
    grid-template-columns: 4vh auto;
    text-align: center;
  }

  .coinbal div i {

    height: 4vh;
    width: 100%;

    background-position: center right;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 45px;


  }

  pre {
    background: #eee;
    padding: 1rem;
    overflow: auto;
    border-radius: 3px;
    max-width: 80ch;
  }

  pre code {
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
  }

  .coinbal p {
    height: min-content;
    width: 100%;
    margin: auto;
    font-size: 2vh;
    text-align: center;
    overflow: hidden;
  }

  .Wallet {
    margin: auto;
    width: 100%;
    padding: "7px";
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 100%;
    overflow: hidden;
    border-radius: 5px;
  }

  .Wallet h1 {
    height: min-content;
    width: 100%;
    font-size: 10px;
    margin: auto auto;

  }


  .bets {
    grid-area: bets;
    height: 60vh;
    width: 100%;
    margin: auto;

    display: grid;
    grid-template-rows: 10vh 90%;


  }

  .bets ul li {
    height: 10vh;
    width: 95%;
    margin: auto;
    display: grid;
    grid-template-columns: 20% 15% 25% 15% 25%;
    align-items: center;
    text-align: center;

  }

  .bet {

    height: min-content;
    width: 100%;

    margin-top: auto;
    margin-bottom: auto;

    border-left: 1px solid #ffffff00;

    align-items: center;
    display: grid;
    grid-template-rows: min-content min-content min-content;
  }

  .Trademainboletamodal2 div {
    position: relative;
    display: grid;
    margin: auto;
    border-radius: 10px;
    background-color: #0e151d;
    border: 1px solid #ffffff05;
    box-shadow: 2px 2px 2px 2px #00000010;
    height: 70vh;
    width: 98%;
  }
}

#canvasContainer {
  position: relative;
  width: 300px;
}

#canvas {
  z-index: 1;
}

#prizePointer {
  position: absolute;
  left: 125px;
  top: 10px;
  z-index: 999;
}

.euro {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: -75px;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
  /*animation: spin 2.5s linear infinite;*/
}

.rotatecoin {
  animation: spin 2.5s linear infinite;
}

.fast {
  animation: spin 0.7s linear infinite;
}

.stop {
  animation: spin 1s linear 0;
}

.back {

  /*background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/133687/backeuro.png");*/
  background-color: #0e151d;
  border-radius: 90px;
  width: 150px;
  height: 150px;
}

.middle {
  /*background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/133687/faceeuro.png");*/
  background-color: #11111155;
  border-radius: 90px;
  width: 150px;
  height: 150px;
  transform: translateZ(1px);
  position: absolute;
  top: 0;
}

.front {
  background-color: #0e151d;
  border-radius: 90px;
  height: 150px;
  position: absolute;
  top: 0;
  transform: translateZ(10px);
  width: 150px;
}

.middle:nth-child(1) {
  transform: translateZ(1px);
}

.middle:nth-child(2) {
  transform: translateZ(2px);
}

.middle:nth-child(3) {
  transform: translateZ(3px);
}

.middle:nth-child(4) {
  transform: translateZ(4px);
}

.middle:nth-child(5) {
  transform: translateZ(5px);
}

.middle:nth-child(6) {
  transform: translateZ(6px);
}

.middle:nth-child(7) {
  transform: translateZ(7px);
}

.middle:nth-child(8) {
  transform: translateZ(8px);
}

.middle:nth-child(9) {
  transform: translateZ(9px);
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}